













































































































































import Vue from 'vue'
import {
  mapActions,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import windowWidth from '@/mixins/windowWidth'
import Pagination from '@/components/Pagination/index.vue'
import Search from '@/components/Search/Search.vue'
import { TheMask, mask } from 'vue-the-mask'
import { DATE_SORTING } from '@/common/constants'

export default Vue.extend({
  name: 'requests',

  components: {
    Loading,
    TheMask,
    Search,
    Pagination
  },

  directives: {
    mask
  },

  mixins: [
    windowWidth
  ],

  data () {
    return {
      searchPlaceholder: 'Введите имя бизнеса или телефон',
      size: 8,
      page: 1,
      loading: false,
      searchValue: '',
      sortByDate: '',
      fields: [
        {
          key: 'created_at',
          label: 'Дата отправки заявки',
          sortable: true
        },
        {
          key: 'username',
          label: 'Логин'
        },
        {
          key: 'name',
          label: 'Имя бизнеса'
        },
        {
          key: 'contact_phone',
          label: 'Номер телефона'
        },
        {
          key: 'location.address',
          label: 'Локация бизнеса'
        }
      ]
    }
  },

  computed: {
    ...mapState('requests', [
      'requests',
      'requestCount'
    ]),

    selectedIds () {
      return this.requests
        .filter(item => item.selected)
        .map(item => item.id)
    },
    selectedRowscontact_phones () {
      return this.requests
        .filter(item => item.selected)
        .map(item => item.contact_phone)
    },

    notActivated () {
      return this.$route.name !== 'requests-active'
    }
  },

  watch: {
    $route () {
      this.page = Number(this.$route.query.page || 1)
      this.searchValue = this.$route.query.search || ''
      this.sortByDate = this.$route.query.orderBy || ''
      this.getRequestsPage()
    },

    searchValue (value) {
      if (!value.length && this.$route.query?.search?.length) {
        this.$router.push(this.$route.path)
      }
    }
  },

  async mounted () {
    this.page = Number(this.$route.query.page || 1)
    this.searchValue = this.$route.query.search || ''
    this.sortByDate = this.$route.query.orderBy || ''
    await this.getRequestsPage()
  },

  methods: {
    ...mapActions('requests', [
      'getRequests'
    ]),

    sortingChanged (ctx) {
      if (!this.sortByDate.length || DATE_SORTING.descending === this.sortByDate) {
        this.sortByDate = DATE_SORTING.ascending
      } else {
        this.sortByDate = DATE_SORTING.descending
      }
      this.setRouteQuery('orderBy', this.sortByDate)
      this.setRouteQuery('page', 1)
    },

    setRouteQuery (paramName: string, paramValue) {
      const query = { ...this.$route.query, [paramName]: paramValue }

      this.$router.push({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    },

    async handleSearch () {
      if (!this.searchValue.trim()) {
        this.$refs['search-input'].$el.focus()
        return
      }

      this.page = 1
      this.setRouteQuery('search', this.searchValue.trim())
    },

    async clearSearchValue () {
      this.searchValue = ''
    },

    changeTab (tabNumber) {
      this.page = tabNumber
      this.setRouteQuery('page', this.page)
    },

    async getRequestsPage () {
      this.loading = true
      const result = await this.getRequests({
        isAllowedForBusiness: !this.notActivated,
        filter_value: this.searchValue,
        page: this.page,
        size: this.size,
        order_by: this.sortByDate
      })
      if (result.status < 200 || result.status >= 300) {
        result?.data?.detail?.message
          ? this.$toast.error(result.data.detail.message)
          : this.$toast.error('Error code: ' + result.status)
        this.loading = false
        return
      }
      this.loading = false
    },

    showDetails (request: any) {
      // if (request.profile_type === 'admin') return
      this.$router.push({ name: 'request-detail', params: { requestId: request.id } })
    }
  }
})
