
































import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'Search',

  props: {
    placeholder: {
      type: String as PropType<string>,
      default: 'Поиск'
    },

    value: {
      type: String as PropType<string>,
      default: null
    },

    type: {
      type: String as PropType<string>,
      default: 'text'
    },

    showButton: {
      type: Boolean as PropType<boolean>,
      default: false
    },

    borderedInput: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },

  methods: {
    input (val: string) {
      if (this.value !== val) {
        this.$emit('input', val)
      }
    },

    handleClear () {
      this.$emit('input', '')
      this.$emit('clear')
      this.$refs['search-input'].$el.focus()
    }
  }
})
